import React from "react";
import GamingVideo from "../../components/gaming/Gaming";

function GamingPage() {
  return (
    <div className="experience-main">
      <GamingVideo />
    </div>
  );
}

export default GamingPage;
