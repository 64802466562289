import Ana from './Icon-Ana.webp'
import Ashe from './Icon-Ashe.webp'
import Bastion from './Icon-Bastion.webp'
import Brigitte from './Icon-Brigitte.webp'
import Cassidy from './Icon-Cassidy.webp'
import D_Va from './Icon-D.Va.webp'
import Doomfist from './Icon-Doomfist.webp'
import Genji from './Icon-Genji.webp'
import Hanzo from './Icon-Hanzo.webp'
import Junkrat from './Icon-Junkrat.webp'
import Kiriko from './Icon-Kiriko.webp'
import Lucio from './Icon-Lucio.webp'
import Mei from './Icon-Mei.webp'
import Mercy from './Icon-Mercy.webp'
import Moira from './Icon-Moira.webp'
import Orisa from './Icon-Orisa.webp'
import Pharah from './Icon-Pharah.webp'
import Reaper from './Icon-Reaper.webp'
import Reinhardt from './Icon-Reinhardt.webp'
import Roadhog from './Icon-Roadhog.webp'
import Sigma from './Icon-Sigma.webp'
import Sojourn from './Icon-Sojourn.webp'
import Soldier_76 from './Icon-Soldier_76.webp'
import Sombra from './Icon-Sombra.webp'
import Symmetra from './Icon-Symmetra.webp'
import Torbjorn from './Icon-Torbjorn.webp'
import Tracer from './Icon-Tracer.webp'
import Widowmaker from './Icon-Widowmaker.webp'
import Winston from './Icon-Winston.webp'
import Wrecking_Ball from './Icon-Wrecking_Ball.webp'
import Zarya from './Icon-Zarya.webp'
import Zenyatta from './Icon-Zenyatta.webp'

const HeroList = [Ana, Ashe, Bastion, Brigitte, Cassidy, D_Va, Doomfist, Genji, Hanzo, Junkrat, Kiriko, Lucio, Mei, Mercy, Moira, Orisa, Pharah, Reaper, Reinhardt, Roadhog, Sigma, Sojourn, Soldier_76, Sombra, Symmetra, Torbjorn, Tracer, Widowmaker, Winston, Wrecking_Ball, Zarya, Zenyatta]

export default HeroList;
